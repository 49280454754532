@use 'common'

.wrapper
	text-align: center

.logo
	padding-top: 2rem

	&_link
		display: inline-block

		&:hover,
		&:focus-within
			opacity: 0.5

.title
	+common.title
	padding-bottom: 2rem

.description
	padding-bottom: 1rem
