$blue: #1B22FA
$dark: #0A0A0E
$niceColor: #57606f
$textColor: #444444
$dangerRed: #FF5758
$chartreuse: #D6F379
$white: #ffffff

$serifStack: serif
$sansSerifStack: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif

$objectSans: objectSans
// $avenirNext: avenirNext
$pangram: pangram
$ppWoodland: ppWoodland
$sourceSans3: sourceSans3
$fustat: fustat

$objectSansStack: $objectSans, $sansSerifStack
// $avenirNextStack: $avenirNext, $sansSerifStack
$pangramStack: $pangram, $sansSerifStack
$ppWoodlandStack: $ppWoodland, $serifStack
$sourceSans3Stack: $sourceSans3, $serifStack
$fustatStack: $fustat, $serifStack

$break22: 22rem // (352px)
$break24: 24rem // (384px)
$break30: 30rem // (480px)
$break32: 32rem // (512px)
$break38: 38rem // (608px)
$break48: 48rem // (768px)
$break62: 62rem // (992px)
$break75: 75rem // (1200px)
$break81: 81.25rem // 1300px
$break92: 92rem // (1472px)

$contentWidth-small: 62rem // 62rem == 992px
$contentWidth-normal: 78rem // 1248px
$contentWidth-wide: 100rem // 100rem == 1600px
$gap: 5rem
$gapMedium: 5rem
$gapSmall: 1.875rem

$gemma-chat-width: 22.5rem

$border-radius-md: 1rem
$border-radius: 2rem
$box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05)
$line-height: 1.625
$letter-spacing: -0.04em

$headerSubNavigationPadding: 3rem
$cardsVerticalPadding: 1.875rem

$dark-theme-gradient-start-color: #101010
$dark-theme-gradient-end-color: #282745
