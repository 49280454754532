@use '../variables'

=title($isLarge: false)
	font-size: 2.5rem
	line-height: 1.22em
	font-weight: 400
	letter-spacing: variables.$letter-spacing
	@if ($isLarge)
		font-size: 3.4rem
	@media (min-width: variables.$break22)
		font-size: 2.5rem
	@media (min-width: variables.$break62)
		font-size: 4.5rem
		font-weight: 400
		line-height: 5rem
		letter-spacing: -0.18rem

=heading2
	font-size: 2rem
	line-height: 1.22em
	font-weight: 400
	letter-spacing: variables.$letter-spacing
	@media (min-width: variables.$break38)
		font-size: 2.75rem
	@media (min-width: variables.$break62)
		font-size: 4rem
		line-height: 4.375rem
		letter-spacing: -0.16rem

=heading3
	font-weight: 400
	font-size: 2rem
	letter-spacing: variables.$letter-spacing
	line-height: 1.11

	@media (min-width: variables.$break32)
		letter-spacing: -0.125rem
		line-height: 2.5rem
		font-size: 2.125rem

	@media (min-width: variables.$break48)
		letter-spacing: -0.125rem
		line-height: 3.5rem
		font-size: 3.125rem

=heading4
	font-weight: 400
	font-size: 2rem
	line-height: 2.5rem
	letter-spacing: -0.04rem
